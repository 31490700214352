import { ChangeEvent, FC, useState } from 'react'
import { To } from 'react-router-dom'
import classNames from 'classnames'
import { Maybe } from 'src/graphql/types'
import { isEmpty } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'

import ChoiceInput from 'src/components/01-atoms/ChoiceInput'
import InlineLink from 'src/components/01-atoms/InlineLink'
import Tag, { Color } from 'src/components/01-atoms/Tag'
import Button from 'src/components/01-atoms/Button'

import { ICustomerAccount } from 'src/utils/types/ICustomerAccount'
import { longFormatDate } from 'src/utils/helpers/date'

export interface IPackageListTableRowProps {
  purchaser: ICustomerAccount & {
    numberOfTotalOrders?: Maybe<number>
    lastPurchaseAt?: Maybe<Date>
    createdAt?: Maybe<Date>
  }
  purchaserLink: To
  headers?: string[]
  selected?: boolean
  selectPurchaserChange?: ( e: ChangeEvent<HTMLInputElement> ) => void
  isSelectable?: boolean
}

const PurchaserListTableRow: FC<IPackageListTableRowProps> = ({
  purchaser,
  purchaserLink,
  headers = [],
  selected = false,
  selectPurchaserChange = () => {},
  isSelectable,
}) => {
  const [ isOpen, setIsOpen ] = useState( false )
  const [ isSelected, setIsSelected ] = useState( selected )
  const toggleOpen = () => setIsOpen( !isOpen )

  const cellClasses = classNames(
    'flex lg:py-2 lg:first-of-type:border-l-0 lg:border-l-2 lg:border-b-2 lg:table-cell lg:px-4 lg:[tr:last-of-type_&]:border-b-0'
  )
  const contentCellClasses = classNames({ 'hidden lg:table-cell': !isOpen }, 'px-4 py-1 gap-4' )
  const fullName = [ purchaser?.lastName, purchaser?.firstName ].filter( Boolean ).join( ', ' )
  const nameToshow = isEmpty( fullName ) ? purchaser?.email : fullName
  const labelClasses = classNames( 'w-1/3 shrink-0 font-bold lg:hidden' )

  const [ , emailHeader, numOrdersHeader, lastPurchaseHeader, , createdAtHeader ] = headers

  const accountType = purchaser?.isGuest
    ? {
        label: 'Guest',
        color: Color.ORANGE,
      }
    : {
        label: 'Registered',
        color: Color.EMERALD,
      }

  return (
    <tr
      data-testid={`purchaser-${purchaser?.id}`}
      className={classNames(
        'flex flex-col relative lg:table-row text-sm border-b-2 last-of-type:border-b-0'
      )}
      data-selected={isSelected}
      data-state={isOpen ? 'open' : 'closed'}
    >
      {isSelectable && (
        <td
          className={classNames(
            cellClasses,
            'absolute top-0 left-0 h-[54px] w-10 center z-20 lg:static lg:order-0 lg:h-auto'
          )}
          data-testid="select-purchaser"
        >
          <ChoiceInput
            labelText={`Select purchaser #${purchaser?.id}`}
            checked={isSelected}
            onChange={( e ) => {
              selectPurchaserChange( e )
              setIsSelected( e.currentTarget.checked )
            }}
            value={purchaser?.id}
            hideLabel
          />
        </td>
      )}
      <td
        className={classNames( cellClasses, 'center-v lg:order-1', {
          'pr-32': !purchaser.isGuest,
          'pr-24': purchaser.isGuest,
          'pl-10': isSelectable,
        })}
        data-testid="purchaser-name"
      >
        <button type="button" className="text-left p-4 flex gap-2 lg:hidden" onClick={toggleOpen}>
          <div className="font-bold">{nameToshow}</div>{' '}
          {!!fullName && <span className="hidden sm:inline">({purchaser.email})</span>}
        </button>
        <InlineLink to={purchaserLink} className="hidden lg:inline">
          {nameToshow}
        </InlineLink>
      </td>

      <td
        className={classNames(
          cellClasses,
          contentCellClasses,
          'order-1 pt-4 sm:hidden lg:table-cell border-t lg:order-2 lg:border-t-0'
        )}
        data-testid="purchaser-email"
      >
        <div className={labelClasses}>{emailHeader}</div>
        <div className="word-wrap break-all max-w-2/3">{purchaser?.email}</div>
      </td>
      <td
        data-testid="number-of-orders"
        className={classNames( cellClasses, contentCellClasses, 'order-3 lg:order-3' )}
      >
        <div className={labelClasses}>{numOrdersHeader}</div>
        {purchaser?.numberOfTotalOrders ?? 0}
      </td>
      <td
        data-testid="last-purchase-on"
        className={classNames( cellClasses, contentCellClasses, 'order-3 lg:order-4' )}
      >
        <div className={labelClasses}>{lastPurchaseHeader}</div>
        {purchaser?.lastPurchaseAt && longFormatDate( purchaser?.lastPurchaseAt )}
      </td>
      <td
        data-testid="account-type"
        className={classNames(
          cellClasses,
          'absolute top-2 right-2 flex flex-row center lg:static lg:order-5'
        )}
      >
        <Tag data-testid="account-type" color={accountType.color}>
          {accountType.label}
        </Tag>

        <button
          type="button"
          className="ml-2 h-8 w-8 center lg:hidden"
          onClick={toggleOpen}
          data-testid="toggle-row"
        >
          <FontAwesomeIcon
            className="font-bold text-lg [[data-state='open']_&]:rotate-180 transition-transform ease-[cubic-bezier(0.88,0,0.12,1)] motion-reduce:transition-none"
            icon={faChevronDown}
            size="sm"
            aria-hidden
          />
        </button>
      </td>
      <td
        data-testid="created-on"
        className={classNames(
          cellClasses,
          contentCellClasses,
          'order-2 sm:pt-4 sm:border-t lg:order-6 lg:border-t-0'
        )}
      >
        <div className={labelClasses}>{createdAtHeader}</div>
        {purchaser?.createdAt && longFormatDate( purchaser?.createdAt )}
      </td>
      <td
        data-testid="purchaser-link"
        className={classNames( contentCellClasses, 'my-2 px-4 order-4 lg:hidden' )}
      >
        <Button to={purchaserLink} className="w-full center">
          View Profile
        </Button>
      </td>
    </tr>
  )
}

export default PurchaserListTableRow
