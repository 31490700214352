import { FC } from 'react'
import { Helmet } from 'react-helmet'

interface IAdminHeadProps {
  pageTitle?: string
}

const AdminHead: FC<IAdminHeadProps> = ({ pageTitle }) => (
  // @ts-expect-error: FIXME: Property 'refs' is missing
  <Helmet>
    <title>{pageTitle || 'Admin'}</title>
  </Helmet>
)

export default AdminHead
