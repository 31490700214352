import classNames from 'classnames'
import { HTMLAttributes, forwardRef, Ref } from 'react'

import ChoiceInput from 'src/components/01-atoms/ChoiceInput'

export interface IFilter {
  label: string
  key: string
  isIndeterminate?: boolean
  indent?: boolean
}
export interface IFiltersProps extends HTMLAttributes<HTMLInputElement> {
  filters: IFilter[]
  type?: 'checkbox' | 'radio'
  ref?: Ref<HTMLInputElement>
  name?: string
}

const Filters = forwardRef<HTMLInputElement, IFiltersProps>(
  ({ filters, type = 'radio', name, ...inputProps }, ref ) => (
    <>
      {filters.map(
        ( filter ) =>
          filter && (
            <div
              className={classNames(
                'mb-4',
                filter.indent && 'ml-6',
                filter.isIndeterminate && 'text-gb-gray-700'
              )}
              key={filter.key}
            >
              <ChoiceInput
                {...inputProps}
                name={name}
                ref={ref}
                type={type}
                labelText={filter.label}
                id={`${type === 'radio' ? 'select' : 'toggle'}-${filter.key}`}
                defaultChecked={
                  inputProps.defaultValue ? inputProps.defaultValue === filter.key : undefined
                }
                value={filter.key !== 'all' ? filter.key : undefined}
              />
            </div>
          )
      )}
    </>
  )
)

export default Filters
