import { InputHTMLAttributes, forwardRef, Ref } from 'react'
import classNames from 'classnames'

import './styles.css'

interface ISlideToggleProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  labelText: string
  hideLabel?: boolean
  variant?: 'small' | 'large'
  ref?: Ref<HTMLInputElement>
}

const SlideToggle = forwardRef<HTMLInputElement, ISlideToggleProps>(
  ({ hideLabel, labelText, className, variant = 'small', ...inputProps }, ref ) => (
    <label className={`${className} toggle flex items-start gap-1`} htmlFor={inputProps.id}>
      <input
        {...inputProps}
        type="checkbox"
        role="switch"
        className={classNames(
          {
            'large min-w-12 min-h-[22px] before:h-4 before:w-4 before:top-0.5 before:left-0.5 before:bottom-0.5':
              variant === 'large',
            'top-0.5 min-w-6 min-h-[12px] before:h-2 before:w-2 before:top-[1px] before:left-[1px]':
              variant === 'small',
          },
          'appearance-none border-solid border cursor-pointer m-0 relative select-none bg-neutral-200 border-neutral-400 before:z-10 rounded-full transition-all before:content-[""] before:bg-neutral-500 checked:before:bg-white before:cursor-pointer before:absolute before:transition-all before:rounded-full after:text-white after:block after:absolute after:leading-none checked:bg-gb-blue-600 checked:border-gb-blue-600 hover:opacity-90 focus:outline focus:outline-offset-2 focus:outline-neutral-500 focus:checked:outline-gb-blue-600 checked:focus:outline disabled:opacity-50 disabled:cursor-auto after:right-[0.4em] after:bottom-[0.5em] after:top-[0.5em] after:text-2xs checked:after:left-[0.4em] checked:before:left-0 checked:after:right-0'
        )}
        ref={ref}
      />

      <span
        className={classNames(
          {
            'sr-only': hideLabel,
            'text-xs ml-0.5': variant === 'small',
            'ml-1': variant === 'large',
          },
          'label '
        )}
      >
        {labelText}
      </span>
    </label>
  )
)

export default SlideToggle
