import { FC, PropsWithChildren } from 'react'

import Logo, { LogoType } from 'src/components/01-atoms/Logo'
import Grid from 'src/components/01-atoms/Grid'

import FoodLoveShop from 'src/assets/ship-food-love-bg.svg?react'

const Login: FC<PropsWithChildren<{}>> = ({ children }) => (
  <Grid className="h-full gap-0">
    <section className="h-full bg-white p-9 flex-col center-v col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3">
      <div>
        <Logo type={LogoType.MERCHANT} className="max-w-[11rem] mb-12" />
        <h1 className="h3 mb-4">Sign In</h1>
        {children}
      </div>
    </section>
    <section className="h-full hidden sm:block sm:col-span-6 lg:col-span-8 xl:col-span-9">
      <FoodLoveShop className="w-full h-full" />
    </section>
  </Grid>
)

export default Login
