import { FC, HTMLProps, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { faArrowUpRightFromSquare, faSearch } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import useSearchAccounts from 'src/utils/hooks/useSearchAccounts'

interface IMerchantSearchProps extends HTMLProps<HTMLInputElement> {
  setMdashAccountId: ( mdashAccountId: number ) => void
}

const MerchantSearch: FC<IMerchantSearchProps> = ({
  setMdashAccountId,
  className,
  ...inputProps
}) => {
  const ref = useRef<HTMLInputElement>( null )
  const [ searchQuery, setSearchQuery ] = useState( '' )
  const [ hasFocus, setHasFocus ] = useState( false )
  const [ hasPerformedSearch, setHasPerformedSearch ] = useState( false )
  const { data: searchData, fetching: isSearching } = useSearchAccounts({ query: searchQuery })

  useEffect(() => {
    if ( isSearching ) {
      setHasPerformedSearch( true )
    }
  }, [ isSearching ])

  return (
    <div className="flex justify-center">
      <div>
        <input
          ref={ref}
          type="text"
          placeholder="Search Accounts..."
          className={classNames( 'p-2 border border-gb-gray-300 w-80', className )}
          value={searchQuery}
          onFocus={() => {
            setHasFocus( true )
            ref.current?.select()
          }}
          onBlur={() => setTimeout(() => setHasFocus( false ), 250 )}
          onChange={( e ) => setSearchQuery( e.target.value )}
          {...inputProps}
        />
        <div className="absolute h-0 z-10">
          <div
            className={classNames(
              'w-80 bg-gb-gray-50 border border-gb-gray-300 mt-2 max-h-48 overflow-y-auto',
              { 'h-0 border-0': !hasFocus || !hasPerformedSearch }
            )}
          >
            {isSearching && <div className="px-2 py-1">Searching...</div>}
            {!isSearching &&
              hasPerformedSearch &&
              searchData &&
              searchData.searchAccounts.length === 0 && (
                <div className="px-2 py-1">No Results Found :(</div>
              )}
            {!isSearching &&
              searchData &&
              searchData.searchAccounts.map(( account ) => (
                <div key={account.id} className="flex justify-between items-center px-2 py-1">
                  <button
                    type="button"
                    className="w-full text-left hover:underline"
                    onClick={() => setMdashAccountId( Number( account.id ))}
                  >
                    {account.name}
                  </button>
                  <Link to={`/${account.id}/manifest`} target="_manifest">
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </div>
      <FontAwesomeIcon icon={faSearch} className="text-2xl -ml-8 pt-2 " />
    </div>
  )
}

export default MerchantSearch
