/* eslint-disable */

import { FC } from 'react'
import { getYear } from 'date-fns'
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons'

import TextInput from 'src/components/01-atoms/TextInput'
import Button from 'src/components/01-atoms/Button'

import { fullMonthName, longFormatDayAndDate } from 'src/utils/helpers/date'

import 'react-datepicker/dist/react-datepicker.css'
import './styles.css'

export interface IDateRangePickerProps extends ReactDatePickerProps<'', false> {
  /**
   * Use a button to make the calendar popover show up instead of a text field.
   */
  triggerWithButton?: boolean

  /**
   * Additional class names to apply to the root element.
   */
  className?: string
}

interface ITriggerButtonProps {
  value?: string
  onClick?: () => void
}

const DatePicker: FC<IDateRangePickerProps> = ({
  className,
  triggerWithButton = false,
  ...props
}) => {
  const buttonTrigger = (
    <Button outline variant="secondary">
      {props.selected ? (
        <>
          <span className="sr-only">Selected date:</span>
          {longFormatDayAndDate(props.selected)}
        </>
      ) : (
        <>Please select a date</>
      )}
    </Button>
  )

  const textInputTrigger = (
    <TextInput className="relative z-0 border border-gb-gray-400 bg-white placeholder:text-gb-gray-800 disabled:placeholder-shown:opacity-50" />
  )

  return (
    // @ts-expect-error: FIXME: Property 'refs' is missing
    <ReactDatePicker
      className={className}
      autoFocus={false}
      {...props}
      popperPlacement="top"
      customInput={triggerWithButton ? buttonTrigger : textInputTrigger}
      renderCustomHeader={({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div className="flex justify-between items-center p-3 pb-1.5">
          <button
            type="button"
            className="hover:text-gb-gray-900 transition-colors text-gb-gray-700 text-xl w-6 h-6 flex items-center justify-center"
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
          <div className="text-xs font-semibold text-gb-gray-900">
            {fullMonthName(date)} {getYear(date)}
          </div>
          <button
            type="button"
            className="hover:text-gb-gray-900 transition-colors text-gb-gray-700 text-xl w-6 h-6 flex items-center justify-center"
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      )}
    />
  )
}

export default DatePicker
