import { InputHTMLAttributes, forwardRef, Ref } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faHyphen } from '@fortawesome/pro-solid-svg-icons'

import './styles.css'

export interface IChoiceInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  type?: 'radio' | 'checkbox'
  labelText?: string
  hideLabel?: boolean
  variant?: 'default' | 'large'
  ref?: Ref<HTMLInputElement>
}

const ChoiceInput = forwardRef<HTMLInputElement, IChoiceInputProps>(
  (
    {
      type = 'checkbox',
      labelText,
      hideLabel = false,
      className,
      variant = 'default',
      children,
      ...inputProps
    },
    ref
  ) => (
    <label
      htmlFor={inputProps.id}
      className={classNames(
        { 'gap-2': !hideLabel, 'py-4 px-6 large': variant === 'large' },
        'choice-input flex flex-start items-center'
      )}
    >
      <input {...inputProps} type={type} className={type} ref={ref} />
      <div className={classNames( 'input-ui text-white transition-colors', className )}>
        {( type === 'checkbox' || variant === 'large' ) && (
          <>
            <FontAwesomeIcon icon={faCheck} className="icon checked selected" />
            <FontAwesomeIcon icon={faHyphen} className="icon indeterminate" />
          </>
        )}

        <div className="icon selected" />
        <div className="box" />
      </div>

      {labelText && <div className={classNames({ 'sr-only': hideLabel })}>{labelText}</div>}
      <div>{children}</div>
    </label>
  )
)

export default ChoiceInput
