import { ChangeEvent, ChangeEventHandler, FC, ReactElement, RefObject } from 'react'

import ChoiceInput from 'src/components/01-atoms/ChoiceInput'
import Table, { ITableProps, TableHeader } from 'src/components/02-molecules/Table'

import useAppParams from 'src/utils/hooks/useAppParams'
import PurchaserListTableRow, {
  IPackageListTableRowProps,
} from 'src/components/02-molecules/PurchaserListTableRow'
import './styles.css'

interface IPurchaserListTableProps
  extends Pick<ITableProps, 'sortValue' | 'sortAction' | 'tableFooterChildren'> {
  purchasers: IPackageListTableRowProps['purchaser'][]
  purchasersAreSelectable?: boolean
  selectedPurchasers?: Set<string | null>
  selectAllRef?: RefObject<HTMLInputElement>
  selectAllHandleChange?: ChangeEventHandler<HTMLInputElement>
  selectPurchaserChange?: ( e: ChangeEvent<HTMLInputElement>, purchaserId: string ) => void
  tableFooterChildren?: ReactElement
}

const PurchaserListTable: FC<IPurchaserListTableProps> = ({
  purchasers,
  purchasersAreSelectable = false,
  selectedPurchasers = null,
  selectAllRef = null,
  selectAllHandleChange = () => {},
  selectPurchaserChange = () => {},
  ...tableProps
}) => {
  const { makeLinkUrls } = useAppParams()

  const headers: TableHeader[] = [
    { name: 'Name' },
    { name: 'Email' },
    { name: 'Orders' },
    { name: 'Last Order Placed On' },
    { name: 'Account Type' },
    { name: 'Created' },
  ]

  const columnWidths = [
    { className: 'lg:w-1/4' },
    {},
    { className: 'lg:w-20' },
    { className: 'lg:min-w-32 lg:w-1/12' },
    { className: 'lg:w-1/12' },
    { className: 'lg:min-w-32 lg:w-1/12' },
  ]

  if ( purchasersAreSelectable ) {
    headers.unshift({
      name: 'Select All',
      element: (
        <div data-testid="action-select-all">
          <ChoiceInput
            labelText="Select All"
            ref={selectAllRef}
            onChange={selectAllHandleChange}
            hideLabel
          />
        </div>
      ),
    })
    columnWidths.unshift({ className: 'md:w-10' })
  }

  return (
    <Table
      tableClasses="purchaser-list-table"
      data-testid="purchaser-list-table"
      borderRule="surround"
      columnWidths={columnWidths}
      headers={headers}
      {...tableProps}
    >
      {purchasers &&
        purchasers.map(( purchaser ) => {
          if ( !purchaser ) return null

          return (
            <PurchaserListTableRow
              key={`purchaser-${purchaser.id}`}
              headers={headers.flatMap(( header ) => header.name )}
              isSelectable={purchasersAreSelectable}
              selected={selectedPurchasers?.has( purchaser.id )}
              selectPurchaserChange={( e: ChangeEvent<HTMLInputElement> ) =>
                selectPurchaserChange( e, purchaser.id )
              }
              purchaser={purchaser}
              purchaserLink={makeLinkUrls().purchaserProfile( purchaser.id )}
            />
          )
        })}
    </Table>
  )
}

export default PurchaserListTable
