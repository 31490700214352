import { addWeeks, isSunday, parseISO, startOfWeek } from 'date-fns'
import { FC, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Calendar from 'src/components/02-molecules/Calendar'
import CalendarNav from 'src/components/02-molecules/CalendarNav'
import { IManifestCalendarInterval } from 'src/graphql/types'
import { dateForManifest, getNextDay } from 'src/utils/helpers/date'

import AdminHead from 'src/pages/elements/AdminHead'
import List from './List'

import useWeeklyShippingIssueCalendarPair from './hooks/useWeeklyShippingIssueCalendarPair'

const IssuesCalendar: FC = () => {
  const navigate = useNavigate()
  const { toShipOn } = useParams()
  const currentDate = toShipOn ? parseISO( toShipOn ) : new Date()
  const nextMonth = dateForManifest( startOfWeek( addWeeks( currentDate, 1 ), { weekStartsOn: 1 }))
  const lastMonth = dateForManifest( startOfWeek( addWeeks( currentDate, -1 ), { weekStartsOn: 1 }))
  const today = dateForManifest( new Date())

  const weeklyData = useWeeklyShippingIssueCalendarPair()
  const weeklyShippingDataForCalendar = useMemo(
    () =>
      weeklyData.map(( day ) => ({
        toShipOn: day.toShipOn,
        toShipCount: day.packagesToShipCount,
        fetching: day.isShippingDataPending,
      })),
    [ weeklyData ]
  )
  const weeklyIssueDataForCalendar = useMemo(
    () =>
      weeklyData.map(( day ) => ({
        toShipOn: day.toShipOn,
        unassignedPackagesCount: 0, // stub this for now until BE can provide this data
        labelIssuesCount: day.packagesWithLabelIssueCount,
        fetching: day.isIssueDataPending,
      })),
    [ weeklyData ]
  )

  useEffect(() => {
    if ( isSunday( currentDate )) {
      navigate( `/admin/issues/${dateForManifest( getNextDay( currentDate ))}` )
    }
  }, [])

  return (
    <>
      <AdminHead pageTitle="Label Issues" />
      <div className="flex w-full justify-center">
        <div className="grid gap-4 max-w-[1280px] w-full p-2">
          <div className="pt-2 sticky top-0 z-20 bg-gb-gray-50 grid gap-2">
            <CalendarNav
              prevLink={{
                pathname: `/admin/issues/${lastMonth}`,
              }}
              todayLink={{
                pathname: `/admin/issues/${today}`,
              }}
              nextLink={{
                pathname: `/admin/issues/${nextMonth}`,
              }}
              className="justify-end"
            />
            <Calendar
              withSaturdayShipping
              dates={weeklyShippingDataForCalendar}
              selectedDate={currentDate}
              issuesCalendar={weeklyIssueDataForCalendar}
              interval={IManifestCalendarInterval.WEEKLY}
              dateLinkObject={{ pathname: '/admin/issues' }}
            />
          </div>
          <List />
        </div>
      </div>
    </>
  )
}

export default IssuesCalendar
