import { FC, useState, useEffect, useMemo } from 'react'
import { Route, Routes, useNavigate, useLocation, useMatch, Navigate } from 'react-router-dom'
import { useQuery } from 'urql'
import { Elements as StripeElements } from '@stripe/react-stripe-js'

import { IMegaphoneType } from 'src/components/01-atoms/Megaphone'
import DefaultLayout from 'src/components/04-templates/DefaultLayout'
import FullViewportLayout from 'src/components/04-templates/FullViewportLayout'
import { IMegaphoneModalProps } from 'src/components/03-organisms/MegaphoneModal'

import Login from 'src/pages/Login'
import Manifest from 'src/pages/Manifest'
import OrderDetail from 'src/pages/OrderDetail'
import PackageDetail from 'src/pages/PackageDetail'
import ProductSummary from 'src/pages/ProductSummary'
import Settings from 'src/pages/Settings'
import FulfillmentSheet from 'src/pages/FulfillmentSheet'
import SlipNLabels from 'src/pages/SlipNLabels'
import Discard from 'src/pages/Discard'
import DiscardAndReprint from 'src/pages/DiscardAndReprint'
import ConfirmReprint from 'src/pages/ConfirmReprint'
import PickList from 'src/pages/PickList'
import WhatsNew from 'src/pages/WhatsNew'
import Report from 'src/pages/Report'
import Adoption from 'src/pages/AdminPages/Adoption'
import Checkout from 'src/pages/Checkout/'
import NewCheckout from 'src/pages/Checkout/New/'
import LogoutModal from 'src/pages/elements/LogoutModal'
import PantryItemLimit from 'src/pages/PantryItemLimit'
import ProtectedByPermission from 'src/pages/elements/ProtectedByPermission'
import AutoAssignment from 'src/pages/AutoAssignment'
import ProductTags from 'src/pages/ProductTaxonomies'
import GiftCardRedemptions from 'src/pages/StatementPages/GiftCardRedemptions'
import Statements from 'src/pages/Statements'
import Statement from 'src/pages/StatementPages/Statement'
import SalesReport from 'src/pages/StatementPages/SalesReport'
import MissedShipments from 'src/pages/StatementPages/MissedShipments'
import Reshipments from 'src/pages/StatementPages/Reshipments'
import ReshipmentIssues from 'src/pages/IssuesPages/ReshipmentIssues'
import Refunds from 'src/pages/StatementPages/Refunds'
import Cancellations from 'src/pages/StatementPages/Cancellations'
import Promotions from 'src/pages/StatementPages/Promotions'
import Others from 'src/pages/StatementPages/Others'
import StatementsLanding from 'src/pages/StatementsLanding'
import PrintMap from 'src/pages/AdminPages/PrintMap'
import SigmaExample from 'src/pages/AdminPages/SigmaExample'
import Issues from 'src/pages/Issues'
import RefundsIssues from 'src/pages/IssuesPages/RefundsIssues'
import MissedShipmentsIssues from 'src/pages/IssuesPages/MissedShipmentsIssues'
import IssuesCalendar from 'src/pages/AdminPages/IssuesCalendar'
import PurchaserProfile from 'src/pages/PurchaserProfile'

import PurchasersReport from 'src/pages/Reports/Purchasers'

import AdminOnly from 'src/pages/elements/AdminOnly'
import RunOnLoadWrapper from 'src/pages/elements/RunOnLoadWrapper'

import useLogging from 'src/utils/hooks/useLogging'
import { stripePromise, stripeOptions } from 'src/utils/helpers/stripeInit'
import UserContext, { IIdentity } from 'src/contexts/UserContext'
import { IChildLink } from 'src/utils/types/IChildLink'

import getUserIdentity from 'src/graphql/queries/getUserIdentity.graphql'
import {
  IGetUserIdentityQuery,
  IGetUserIdentityQueryVariables,
} from 'src/graphql/queries/getUserIdentity.types'
import getMDashAccount from 'src/graphql/queries/getMdashAccount.graphql'
import {
  IGetMdashAccountQuery,
  IGetMdashAccountQueryVariables,
} from 'src/graphql/queries/getMdashAccount.types'
import getMetabaseReportsByCategory from 'src/graphql/queries/getMetabaseReportsByCategory.graphql'
import {
  IGetMetabaseReportsByCategoryQuery,
  IGetMetabaseReportsByCategoryQueryVariables,
} from 'src/graphql/queries/getMetabaseReportsByCategory.types'

import getMegaphone from 'src/graphql/queries/getMegaphone.graphql'
import {
  IGetMegaphoneQuery,
  IGetMegaphoneQueryVariables,
} from 'src/graphql/queries/getMegaphone.types'
import getMegaphoneModal from 'src/graphql/queries/getMegaphoneModal.graphql'
import {
  IGetMegaphoneModalQuery,
  IGetMegaphoneModalQueryVariables,
} from './graphql/queries/getMegaphoneModal.types'

import 'src/styles/global.css'

const App: FC = () => {
  const log = useLogging()
  const location = useLocation()
  const navigate = useNavigate()
  const mdashAccountMatches = useMatch( ':mdashAccountId/*' )

  const [ showLogoutModal, setShowLogoutModal ] = useState( false )

  const mdashAccountIdFromURL: string | null | undefined =
    parseInt( mdashAccountMatches?.params.mdashAccountId ?? '', 10 ) > 0
      ? mdashAccountMatches?.params.mdashAccountId
      : null

  const [ userInfo, setUserInfo ] = useState<IIdentity>({
    userId: '',
    userName: '',
    mdashAccountId: mdashAccountIdFromURL ?? '',
    mdashAccountName: '',
    isAdmin: false,
    reportLinks: {},
  })

  const [{ fetching, data, error }] = useQuery<
    IGetUserIdentityQuery,
    IGetUserIdentityQueryVariables
  >({ query: getUserIdentity })

  const [{ data: megaphoneData }] = useQuery<IGetMegaphoneQuery, IGetMegaphoneQueryVariables>({
    query: getMegaphone,
  })

  const [{ data: accountData }, refetchMDashAccount ] = useQuery<
    IGetMdashAccountQuery,
    IGetMdashAccountQueryVariables
  >({
    query: getMDashAccount,
    variables: {
      id: mdashAccountIdFromURL,
    },
    pause: Number( mdashAccountIdFromURL || 0 ) < 1,
    requestPolicy: 'cache-and-network',
  })

  const [{ data: megaphoneModalData }] = useQuery<
    IGetMegaphoneModalQuery,
    IGetMegaphoneModalQueryVariables
  >({
    query: getMegaphoneModal,
    variables: {
      mdashAccountId: mdashAccountIdFromURL,
    },
    pause: Number( mdashAccountIdFromURL || 0 ) < 1,
  })

  const [{ data: reportsData }] = useQuery<
    IGetMetabaseReportsByCategoryQuery,
    IGetMetabaseReportsByCategoryQueryVariables
  >({
    query: getMetabaseReportsByCategory,
    variables: {
      mdashAccountId: mdashAccountIdFromURL ?? '',
    },
    pause: Number( mdashAccountIdFromURL || 0 ) < 1 && !userInfo.isAdmin,
  })

  const markdown = megaphoneData?.megaphone?.messageBody
  const type = megaphoneData?.megaphone?.megaphoneType as IMegaphoneType
  const megaphoneProps = { markdown, type }

  const modalProps = megaphoneModalData?.megaphoneModal

  const reportsAsLinks = ( category: string ): IChildLink[] | undefined => {
    const report = reportsData?.metabaseReportsByCategory?.filter(( r ) => r.category === category )[0]

    if ( report && report.reports ) {
      return report.reports.map(( report ) => ({
        to: report.url
          ? `${userInfo.mdashAccountId}/${report.url}`
          : `${userInfo.mdashAccountId}/report/${report?.id ?? ''}`,
        text: report?.name ?? '',
      }))
    }

    return undefined
  }

  const userContextValue = useMemo(
    () => ({
      identity: userInfo,
      setIdentity: setUserInfo,
    }),
    [ userInfo ]
  )

  useEffect(() => {
    if ( fetching || error || !data || !data.identity ) return

    const { adminUser, merchantUser } = data.identity
    const isAdmin = !!adminUser?.id
    const userId = isAdmin ? adminUser?.id : merchantUser?.id
    const userName = isAdmin ? adminUser?.name : merchantUser?.name
    const mdashAccountId =
      isAdmin && mdashAccountIdFromURL ? mdashAccountIdFromURL : merchantUser?.mdashAccount.id
    const mdashAccountName = merchantUser?.mdashAccount?.name
    const merchantUserPermissions = merchantUser?.permissions
    const displayPackageBarcode = accountData?.mdashAccount?.displayPackageBarcode
    const displaySku = accountData?.mdashAccount?.displaySku
    const useDryIcePlaceholder = accountData?.mdashAccount?.dryIceUsePlaceholder
    const packingSlipVersion = (( accountData?.mdashAccount?.packingSlipVersion ?? 2 ) - 1 ) as 1 | 2
    const isMultiPickUpLocation = merchantUser?.mdashAccount.isMultiPickUpLocation
    const isMultiShippingFacility = merchantUser?.mdashAccount.isMultiShippingFacility
    const isPickUpEnabled = merchantUser?.mdashAccount.isPickUpEnabled
    const defaultMerchantId = accountData?.mdashAccount?.defaultMerchantId
    const accountQueryOverride =
      Number( accountData?.mdashAccount?.id || 0 ) > 0
        ? {
            mdashAccountName: accountData?.mdashAccount?.name,
            mdashAccountPermissions: accountData?.mdashAccount?.permissions,
            isMultiPickUpLocation: accountData?.mdashAccount?.isMultiPickUpLocation,
            isMultiShippingFacility: accountData?.mdashAccount?.isMultiShippingFacility,
            shippingFacilities: accountData?.mdashAccount?.shippingFacilities,
          }
        : {}

    const statements = reportsAsLinks( 'statements' )
    const performanceLinks = reportsAsLinks( 'performance' )
    const productsLinks = reportsAsLinks( 'products' )
    const purchasersLinks = reportsAsLinks( 'purchasers' )

    const reportLinks = {
      performanceLinks: performanceLinks?.length ? performanceLinks : undefined,
      productsLinks: productsLinks?.length ? productsLinks : undefined,
      purchasersLinks: purchasersLinks?.length ? purchasersLinks : undefined,
    }

    setUserInfo(( prev ) => ({
      ...prev,
      userId,
      userName,
      merchantUserPermissions,
      mdashAccountId,
      mdashAccountName,
      displayPackageBarcode,
      displaySku,
      packingSlipVersion,
      isAdmin,
      isMultiPickUpLocation,
      isMultiShippingFacility,
      isPickUpEnabled,
      useDryIcePlaceholder,
      defaultMerchantId: defaultMerchantId ?? undefined,
      showStatements: reportsData?.metabaseReportsByCategory
        ? typeof statements !== 'undefined'
        : undefined,
      reportLinks,
      ...accountQueryOverride,
    }))

    if ( location.pathname !== '/login' && !userId ) {
      navigate( '/login', { replace: true })
    }
  }, [ fetching, data, error, accountData, reportsData ])

  useEffect(() => {
    window.analytics.page( location.pathname )
  }, [ location.pathname ])

  if ( fetching ) return null

  if ( !data || error ) {
    log(
      'MDashX has lost connection with the monolith. User has been sent to the login page.',
      { error, data, userInfo },
      'critical'
    )
    if ( location.pathname !== '/login' ) {
      return <Navigate to="/login" replace />
    }
  }

  /**
   * Keep the routes here in sync with the link Urls in useAppParams.ts as much as possible.
   */
  return (
    <UserContext.Provider value={userContextValue}>
      <Routes>
        <Route element={<FullViewportLayout />}>
          <Route index element={<Login />} />
          <Route path="login">
            <Route index element={<Login />} />
          </Route>
        </Route>

        <Route
          element={
            <DefaultLayout
              {...userInfo}
              {...megaphoneProps}
              modal={modalProps as IMegaphoneModalProps}
              logoutButtonProps={{
                logoutButtonAction: () => setShowLogoutModal( true ),
                disabled: showLogoutModal,
              }}
              linkPrefix={userInfo.mdashAccountId}
            />
          }
        >
          <Route path="/admin" element={<AdminOnly />}>
            <Route index element={<IssuesCalendar />} />
            <Route path="adoption" element={<Adoption />} />
            <Route path="printmap" element={<PrintMap />} />
            <Route path="issues">
              <Route index element={<IssuesCalendar />} />
              <Route path=":toShipOn" element={<IssuesCalendar />} />
            </Route>

            {/* Setting up this temporary example for the Data team */}
            <Route path="sigma-example" element={<SigmaExample />} />
          </Route>
          <Route path="/:mdashAccountId">
            <Route index element={<Manifest />} />
            <Route path="manifest" element={<Manifest />}>
              <Route path=":toShipOn" element={<Manifest />} />
            </Route>
            <Route path="whats_new" element={<WhatsNew />} />

            <Route path="labels" element={<RunOnLoadWrapper fn={refetchMDashAccount} />}>
              <Route path=":toShipOn">
                <Route path="discard" element={<Discard />} />
                <Route path="reprint" element={<DiscardAndReprint />} />
                <Route path="confirm_reprint" element={<ConfirmReprint />} />
                <Route path="print" element={<SlipNLabels />} />
              </Route>
            </Route>

            <Route path="issues/" element={<Issues />}>
              <Route index element={<MissedShipmentsIssues />} />
              <Route path="reshipments" element={<ReshipmentIssues />} />
              <Route path="missed_shipments" element={<MissedShipmentsIssues />} />
              <Route path="refunds" element={<RefundsIssues />} />
            </Route>

            <Route path="fulfillment_sheets/:toShipOn" element={<FulfillmentSheet />} />

            <Route path="pick_list" element={<PickList />}>
              <Route path=":toShipOn" element={<PickList />} />
            </Route>

            <Route
              element={<ProtectedByPermission accountPermissions={[ 'canQueryPurchaserInfo' ]} />}
            >
              <Route path="order/:orderId" element={<OrderDetail />} />
              <Route path="purchaser/:purchaserId" element={<PurchaserProfile />} />
              <Route path="report/purchasers" element={<PurchasersReport />} />
            </Route>
            <Route path="package/:packageId" element={<PackageDetail />} />
            <Route path="subproducts/:toShipOn?" element={<PantryItemLimit />} />
            <Route path="report/:reportId" element={<Report />} />
            <Route path="auto_assignments" element={<AutoAssignment />} />
            <Route path="product_tags" element={<ProductTags />} />

            <Route
              path="checkout"
              element={<ProtectedByPermission userPermissions={[ 'canUseGuac' ]} />}
            >
              <Route
                path=":orderId"
                element={
                  <StripeElements stripe={stripePromise} options={stripeOptions}>
                    <Checkout />
                  </StripeElements>
                }
              />
              <Route path="new" element={<NewCheckout />}>
                <Route path=":purchaserAccountId" />
              </Route>
            </Route>

            <Route path="statements" element={<StatementsLanding />}>
              <Route index element={<Statements />} />
              <Route path=":statementId" element={<Statement />} />
              <Route path=":statementId/sales" element={<SalesReport />} />
              <Route path=":statementId/missed_shipments" element={<MissedShipments />} />
              <Route path=":statementId/reshipments" element={<Reshipments />} />
              <Route path=":statementId/refunds" element={<Refunds />} />
              <Route path=":statementId/cancellations" element={<Cancellations />} />
              <Route path=":statementId/promotions" element={<Promotions />} />
              <Route path=":statementId/gift_card_redemptions" element={<GiftCardRedemptions />} />
              <Route path=":statementId/others" element={<Others />} />
            </Route>

            <Route path="summary" element={<ProductSummary />}>
              <Route path=":toShipOn" element={<ProductSummary />} />
            </Route>
            <Route path="settings" element={<Settings />} />
          </Route>
        </Route>
      </Routes>
      <div className="hidden">Built on {new Date().toUTCString()}</div>
      <LogoutModal open={showLogoutModal} handleClose={() => setShowLogoutModal( false )} />
    </UserContext.Provider>
  )
}

export default App
