query getPurchasersForMerchant($query: String, $mdashAccountId: ID, $page: Int, $limit: Int) {
  purchaserAccountsTable(query: $query, mdashAccountId: $mdashAccountId) {
    purchaserAccounts(page: $page, limit: $limit) {
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
      collection {
        id
        firstName
        lastName
        email
        numberOfTotalOrders
        lastPurchaseAt
        isGuestAccount
        createdAt
      }
    }
  }
}