import { FC } from 'react'
import Helmet from 'react-helmet'
import useAppParams from 'src/utils/hooks/useAppParams'

interface IMDashHeadProps {
  pageTitle?: string
}

const MDashHead: FC<IMDashHeadProps> = ({ pageTitle }) => {
  const { mdashAccountName } = useAppParams()

  const titleSections = `${[ mdashAccountName, pageTitle ].filter(( v ) => !!v ).join( ' | ' )} | `

  return (
    // @ts-expect-error: FIXME: Property 'refs' is missing
    <Helmet>
      <title>{titleSections}MDashX</title>
      <meta name="description" content="Goldbelly Merchant Dashboard" />
      <link rel="icon" href="/favicon.svg" />
      {mdashAccountName && import.meta.env.VITE_ZENDESK_KEY && (
        <script
          id="ze-snippet"
          src={`https://static.zdassets.com/ekr/snippet.js?key=${import.meta.env.VITE_ZENDESK_KEY}`}
        />
      )}
    </Helmet>
  )
}

export default MDashHead
