import { useRef, RefObject, useEffect, useState, forwardRef, Ref } from 'react'
import { kebabCase } from 'lodash'
import { Item, Header, Trigger, Content } from '@radix-ui/react-accordion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons'

import Filters, { IFilter, IFiltersProps } from 'src/components/02-molecules/Filters'

export interface IFilterGroupProps extends IFiltersProps {
  title: string
  filters: IFilter[]
  ref?: Ref<HTMLInputElement>
  name?: string
}

const FilterGroup = forwardRef<HTMLInputElement, IFilterGroupProps>(
  ({ title, children, type = 'radio', ...filterProps }, ref ) => {
    const triggerRef = useRef() as RefObject<HTMLButtonElement>
    const [ isOpen, setIsOpen ] = useState<boolean>()

    useEffect(() => {
      setIsOpen( triggerRef.current?.getAttribute( 'data-state' ) === 'open' )
    }, [])

    return (
      <Item
        value={title}
        className="border-t-2 border-solid border-gb-gray-400 bg-white"
        data-testid={`filter-${kebabCase( title )}`}
      >
        <Header className="m-0">
          <Trigger
            ref={triggerRef}
            onClick={() => setIsOpen( !isOpen )}
            className="py-2 px-4 text-sm spread-h w-full text-gb-gray-900"
          >
            {title}
            <FontAwesomeIcon
              className="font-bold text-lg"
              icon={isOpen ? faChevronUp : faChevronDown}
              size="sm"
            />
          </Trigger>
        </Header>
        <Content className="py-3 px-4 text-xs">
          {children}
          <Filters type={type} ref={ref} {...filterProps} />
        </Content>
      </Item>
    )
  }
)

export default FilterGroup
